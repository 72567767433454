import React, { MouseEvent } from 'react';
import './Button.css';

interface IButtonProps {
  label: string;
  id: string;
  onClick(msg: string): any;
}


class Button extends React.Component<IButtonProps> {

  clickHandler (e: MouseEvent<HTMLButtonElement>)
  {
    this.props.onClick(this.props.id)
  }

  render(): React.ReactNode {
    return <button className="btnx" onClick={this.clickHandler.bind(this)}>{this.props.label}</button>
  }
}

export default Button;
